import { useCallback, useState } from 'react'
import { VisuallyHidden } from '@overdose/components'
import { IconShoppingBag } from '@tabler/icons-react'
import { Button } from '~/components/Button/'
import { AddToCartButtonProps } from '~/components/ProductCard/ProductCard.types'
import { useCart, useCartDrawer } from '~/hooks'
import { useAlgoliaSearchInsignts } from '~/hooks/useAlgoliaSearchInsignts'
import { GTM, GTMEvent } from '~/lib'

export const AddToCartButton = ({
  sku,
  inStock,
  titleSection,
  queryId,
  isButtonOnly = true,
  externalUrl,
}: AddToCartButtonProps) => {
  const { addToCart } = useCart()
  const { openCartDrawer } = useCartDrawer()
  const { sendAddToCartEvent } = useAlgoliaSearchInsignts()
  const [productQuantity, setProductQuantity] = useState<number>(1)

  const handleAddToCart = useCallback(() => {
    addToCart.mutate(
      [
        {
          sku,
          quantity: productQuantity || 1,
          algoliaQueryId: queryId,
          itemContainer: titleSection,
        },
      ],
      {
        onSuccess(data) {
          if (data) {
            GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
            GTM.dispatch(GTMEvent.CART_CONTENTS_PUSH, data)
            sendAddToCartEvent([sku], data.lineItems, data.subTotal, queryId)
          }
          openCartDrawer()
        },
      }
    )
  }, [
    sku,
    productQuantity,
    queryId,
    addToCart,
    openCartDrawer,
    sendAddToCartEvent,
  ])

  const renderStandaloneButton = () => {
    return (
      <Button
        loading={addToCart.isLoading}
        data-id='addToCartButton'
        size='sm'
        icon={<IconShoppingBag />}
        iconOnly
        shape='circle'
        href={externalUrl}
        onClick={() => {
          if (!externalUrl) {
            handleAddToCart()
          }
        }}
        data-testid='add-to-cart-button'
        disabled={!inStock}
        className='overflow-hidden'>
        <VisuallyHidden>
          {externalUrl ? 'Buy Online' : 'Add to cart'}
        </VisuallyHidden>
      </Button>
    )
  }

  const renderButton = () => {
    if (isButtonOnly) {
      return renderStandaloneButton()
    }

    const getAddToCartLabel = () => {
      if (externalUrl) {
        return 'Buy Online'
      }
      if (!inStock) {
        return 'Sold Out'
      }
      return 'Add to Cart'
    }

    const addToCartLabel = getAddToCartLabel()

    return (
      <Button
        data-id='addToCartButton'
        status='primary'
        size='xs'
        theme={{ root: 'basis-3/4 md:basis-full flex-1 px-2 normal-case' }}
        href={externalUrl}
        onClick={() => {
          handleAddToCart()

          if (productQuantity === 0) {
            setProductQuantity(1)
          }
        }}
        loading={addToCart.isLoading}
        disabled={!inStock}>
        {addToCartLabel}
      </Button>
    )
  }

  return <>{renderButton()}</>
}
